<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Paparan Pemda Kota</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">No</th>
                                          <th rowspan="2">Nama Kota</th>
                                          <th rowspan="2">Total Nilai Paparan</th>
                                          <!-- <th rowspan="2">Peringkat</th> -->
                                          <th rowspan="2">Catatan</th>
                                          <th style="text-align:center;" colspan="2">Unduh</th>
                                          <th rowspan="2">Aksi</th>
                                      </tr>
                                      <tr>
                                          <th>PPT</th>
                                          <th>Lampiran</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td><div style="width:150px">{{row.daerah}}</div></td>
                                          <td><div style="width:50px">{{format_desimal(row.total_pemaparan)}}</div></td>
                                          <!-- <td>
                                              <div style="text-align:center;" v-if="row.peringkat_akhir == 1000">
                                                  Keluar
                                              </div>
                                              <div v-else style="text-align:center;">{{row.peringkat_akhir}}</div>
                                          </td> -->
                                          <td><button data-toggle="modal" data-target=".catatan" @click="getcatatan(row.idptsp)" style="margin:5px" class="btn btn-warning">Lihat</button></td>
                                          <td>
                                            <div v-if="row.file_ppt_pemaparan != null">
                                                <a :href="row.file_ppt_pemaparan" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                          </td>
                                          <td>
                                            <div v-if="row.file_lampiran_pemaparan != null">
                                                <a :href="row.file_lampiran_pemaparan" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                          </td>
                                          <td><div style="width:250px">
                                            <b-button variant="primary" style="margin:5px" @click="calculate(row.idptsp)">Calculate</b-button>
                                            <button data-toggle="modal" data-target=".bd-example-modal-lg" @click="lihat(row.idptsp)" style="margin:5px" class="btn btn-success">Lihat</button>
                                            <button @click="badaerah(row.idptsp)" style="margin:5px" class="btn btn-warning">BA</button>
                                            <!-- <button v-if="row.peringkat_akhir === 1000" @click="setPeringkatBaru(row)" hidden style="margin:5px" class="btn btn-danger">Keluar</button>
                                            <button v-else @click="setPeringkatBaru(row)" style="margin:5px" class="btn btn-danger">Keluar</button> -->
                                          </div></td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <button data-toggle="modal" data-target="#daerah" class="btn btn-success"><i class="fa fa-print"></i> Cetak Berita Acara</button>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- Modal lihat penilaian -->
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Penilaian</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama Kota</th>
                                            <th>Penilai</th>
                                            <th>Nilai Pemaparan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in penilaian" :key="row.id">
                                            <td></td>
                                            <td>{{row.peserta}}</td>
                                            <td>
                                                {{row.namapenilai}}
                                            </td>
                                            <td>
                                                {{format_desimal(row.total_skor)}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- data catatan -->
            <div class="modal fade catatan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                    <table id="example3" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{row.judul}}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{row.tipe}}</div>
                                                    <div v-else style="color:blue">{{row.tipe}}</div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-catatan" @click="getPerCatatan(row.id)" style="margin:5px" class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- lihat catatan -->
            <div class="modal fade" id="modal-catatan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                   

                                    <div class="form-group row">
                                        <label class="col-4">Tipe</label>
                                        <div class="col-8">
                                            <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.judul" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Lembaga</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.lembaga" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker disabled  v-model="getCatatan.tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker disabled  v-model="getCatatan.tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <!-- <vue-editor disabled v-model="getCatatan.deskripsi" /> -->
                                            <div v-html="getCatatan.deskripsi"></div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input  disabled type="text" v-model="getCatatan.sumber" class="form-control"/>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Unggah Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file>
                                            <div v-if="getCatatan.file !== null">
                                                <a :href="getCatatan.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            
                        </div>
                        </div>
                    </div>
                </div>

            <!-- modal masukkan daerah-->
            <div class="modal fade" id="daerah" tabindex="-1" role="dialog" aria-labelledby="persetujuan" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="persetujuan">Menyetujui Pemaparan</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="alasan" class="form-group row">
                        <label class="col-lg-4 col-form-label">Daerah</label>
                            <div class="col-lg-8">
                                <input type="text" v-model="daerah" class="form-control"/>
                            </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-primary" @click="cetakba" style="background-color: #030A8C; border-color: #030A8C" data-dismiss="modal">Simpan</button>
                </div>
                </div>
            </div>
            </div>

        </div>
    <!-- </div>
  </div> -->


  
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        tahun: new Date().getFullYear(),
        user: JSON.parse(localStorage.getItem('user')),
        totskor:[],
        idruangan:'',
        daerah:'',
        ruanganpenilai:[],
        noCatatan:'',
        timpenilai:[],
        p1a:'',
        p1b:'',
        p1c:'',
        p2a:'',
        p2b:'',
        p3a:'',
        p3b:'',
        datakabupaten:[],
        show:false,
        penilaian:[],
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        catatann:[],
      }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Paparan Pemda Kota" }]);
    this.getdata()
    this.getpenilai()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    getpenilai(){
        axios.get(this.url+"/tim_penilai?filter=username,like,timpenilai%", {
                headers: {
                    'xth': this.token
                }
            })
      .then(response => {
          var data = response.data.data;
          for (let index = 0; index < data.length; index++) {
                this.timpenilai.push({
                    id: parseInt(data[index].id),
                    nama:data[index].name,
                })
            }
           this.gettimteknis()
      }).catch(error=>{  
        return error
      });
    },
    gettimteknis(){
        axios.get(this.url+"/users?filter=username,like,timteknispenilai%", {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            var result = response.data.data;
            for(let i=0;i<result.length;i++){
                let temp = result[i]['alamat'].split("-");
                if(temp.length <= 1){
                    continue;
                }
                this.timpenilai.push({
                    id: parseInt(result[i].id),
                    nama:result[i].name,
                })
            }
        }).catch(error=>{
            console.log(error)
            return error
        });
    },

    // keluarin ptsp
    setPeringkatBaru(peringkatbaru){
        Swal.fire({
        title: 'Apakah anda yakin?',
        text: "Anda tidak akan dapat mengembalikan ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya'
        }).then((result) => {
        if (result.isConfirmed) {
            const id = peringkatbaru.id_nominee;
            const peringkatakhir = 1000;
            console.log(peringkatakhir);
            axios.put(this.url+"/nominee_kota/"+id,
            {
                peringkat_akhir:peringkatakhir
            }, {
                headers: {
                    'xth': this.token
                }
            }).then((res) => {
                        Swal.fire('Berhasil diubah', '', 'success')
                        this.getdata()
                    return res;
                    })
                    .catch((err) => {
                        Swal.fire(err.response.data.message, '', 'error')
                        return err;
                });
        }
        })
  
    },

    badaerah(idptsp){
        axios
            .get(this.url+"/berita_acara/pemaparan?idpeserta="+idptsp,
            {
                headers: {
                    'xth': this.token
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Cetak BA', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                // this.loaddata()
                console.log(res.data.data.file_ba_pemaparan);
                window.open(res.data.data.file_ba_pemaparan, '_blank');
                
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Cetak BA', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },

    // loaddata pemaparan
    loaddata(){
      this.totskor = null;
      axios.get(this.url+"/tabel_nomine_kota?filter=tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
      .then(response => {
          var nominee = response.data.data;
          this.totskor = nominee;
          this.initDatatable();
      }).catch(error=>{
          return error
          });
            
    },

    // data catatan
    getcatatan(idptsp){
        $('#example3').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_kota?filter=idptsp,=,"+idptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                var catatanomine = response.data.data
                this.catatann = catatanomine;
                this.initDatatable3()
            }).catch(error=>{
                return error
                });
    },

    // lihat catatan
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_kota?filter=id,=,"+id, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idptsp = response.data.data[0].idptsp;
                this.getCatatan.file = response.data.data[0].file;
        });
    },

    // cetak ba
    cetakba(){
        const tahun = this.tahun;
        const daerah = this.daerah;
        axios
            .post(this.url+"/total_pemaparan_kota_ba",
            {
                tahun,
                daerah,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Cetak BA', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                window.open(res.data.data.file_ba, '_blank');
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Cetak BA', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },

    // calculate
    calculate(id) {
        axios.put(this.url+`/gabung_skor_pemaparan`,
            {
                idpeserta:id,
                tahun:this.tahun
            }
            ,{
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Dicalculate', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast(err.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },

    // lihat nilai pemaparan
    lihat(idptsp){
        $('#example2').DataTable().destroy();
        this.penilaian = null,
        axios.get(this.url+"/tabel_nilai_peserta_pemaparan?filter=idpeserta,=,"+idptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                var penilaianomine = response.data.data;
                var j = 0;
                var k =0;
                var l = 0;
                for (j in penilaianomine) {
                    for (k in this.timpenilai) {
                        if (this.timpenilai[k].id == penilaianomine[j].idpenilai) {
                            penilaianomine[j]['namapenilai'] = this.timpenilai[k].nama
                            console.log(this.timpenilai[k].nama)
                        }
                    }
                    for (l in this.totskor) {
                        if (this.totskor[l].idptsp == penilaianomine[j].idpeserta) {
                            penilaianomine[j]['peserta'] = this.totskor[l].daerah
                            console.log(this.totskor[l].nama)
                        }
                    }
                }
                this.penilaian = penilaianomine;
                console.log(response.data.data)
                this.initDatatable2()
            }).catch(error=>{
                return error
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            dom: 'Bfrtip',
            "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //debugger;
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
              },
              columnDefs: [{
                targets: 0,
                render: function(data, type, row, meta) {
                console.log(meta.row);
                console.log(type == 'export'? meta.row : data);
                  return type == 'export'? meta.row + 1 : data;
                }
              }],
              buttons: [
                // 'excel', 'pdf',
                {
                  extend: 'excelHtml5',
                    title: 'PAPARAN PEMDA KOTA KEMENTERIAN INVESTASI/BKPM',
                    text : 'Excel',
                    exportOptions: {
                        columns: [ 1, 2,],
                        orthogonal: 'export',
                        format: {
                            header: function ( data, columnIdx ) {
                            
                                  return data;
                            
                            },
                            body: function(data, row, column, node) {
                                data = $('<p>' + data + '</p>').text();
                                return data.replace(',', '.');
                            },
                        }
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Paparan Pemda Kota ' + date;
                    },
                },
                {
                    extend: 'pdf',
                    text : 'PDF',
                    orientation: 'potrait',
                    title: 'PAPARAN PEMDA KOTA KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [1, 2]
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Paparan Pemda Kota ' + date;
                    },
                }
            ],
            order: [[2, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    initDatatable3() {
        setTimeout(() => {
        var t =  $('#example3').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
};
</script>
